import axiosEx from '@/configs/http/external-api-axios'
import axiosIn from '@/configs/http/internal-api-axios'
import { CORS_EVERY_WHERE_URL, STATUS_CODE } from '@/utils/constants'
import get from 'lodash-es/get'
import store from '@/store/store'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import { MESSAGES } from '@/utils/messages'

const baseUrl = 'api/v1/service/salesforce'
let maxFailAllowSalesForce = 2

async function getAccessToken(params) {
  const url = process.env.VUE_APP_SALESFORCE_OAUTH_URL
  const response = await axiosEx
    .post(`${CORS_EVERY_WHERE_URL}${url}`, null, {
      params,
      crossOrigin: true,
    })
    .then((res) => {
      maxFailAllowSalesForce = 2
      return res
    })
    .catch(() => {
      if (maxFailAllowSalesForce-- === 0) {
        maxFailAllowSalesForce = 2
        return { data: false }
      }
      return getAccessToken(formData)
    })
  if (!response) {
    return false
  }
  return response.data
}

function getDialogState() {
  /*
  true function can be used
  */
  return {
    ADD: false,
    CREATE: true,
    INVITE: false,
    DELETE: false,
    FETCH: true,
  }
}

async function fetchListUser(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}`
  return axiosIn
    .get(url)
    .then(async (res) => {
      return needRefreshToken(res.data, () => fetchListUser(subscribe_apps_id))
    })
    .catch((err) => {
      return err
    })
}

async function addUser(subscribe_apps_id, userIds, profile_id) {
  const url = `${baseUrl}/${subscribe_apps_id}/add`
  return axiosIn
    .post(url, { user_id: userIds.toString(), profile_id })
    .then(async (res) => {
      const errorMessage = get(res.data, 'errorCode', '')
      const statusCode = get(res.data, 'statusCode', '')
      if (errorMessage && statusCode === STATUS_CODE.BAD_REQUEST) {
        return handleError(errorMessage)
      }
      return needRefreshToken(res.data, () => addUser(subscribe_apps_id, userIds, profile_id))
    })
    .catch((err) => {
      return err
    })
}

async function deleteUser(subscribe_apps_id, userIds, profile_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}/remove`
  return axiosIn
    .delete(url, { data: { user_id: userIds.toString(), profile_id } })
    .then(async (res) => {
      const errorMessage = get(res.data, 'error', '')
      const errorCode = get(res.data, 'errorCode', '')
      const statusCode = get(res.data, 'statusCode', '')
      if (errorCode && statusCode === STATUS_CODE.BAD_REQUEST) {
        return handleError(errorCode, errorMessage)
      }
      return needRefreshToken(res.data, () => deleteUser(subscribe_apps_id, userIds, profile_id))
    })
    .catch((err) => {
      return err
    })
}

function getSaaSAccountFields() {
  return [
    { key: 'external_id', label: 'アカウントID', sortable: true },
    { key: 'salesforce_info_name', label: 'プロフィールID', sortable: true },
    { key: 'name', label: 'ユーザー名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'integrate_info_user_code', label: '従業員ID', sortable: true },
    { key: 'integrate_info_name', label: '従業員', sortable: true },
    { key: 'integrate_info_department_name', label: '部署', sortable: true },
    { key: 'created_at', label: '連携日時', sortable: true },
    { key: 'linking', label: '連携方法', sortable: true },
    { key: 'integrate_info_status', label: 'ステータス', sortable: true },
  ]
}

function getSaaSRemoveDialogFields() {
  return [
    { key: 'checkbox', label: '', sortable: false },
    { key: 'external_id', label: 'アカウントID', sortable: true },
    { key: 'salesforce_info_name', label: 'プロフィールID', sortable: true },
    { key: 'name', label: 'ユーザー名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'integrate_info_user_code', label: '従業員ID', sortable: true },
    { key: 'integrate_info_name', label: '従業員', sortable: true },
    { key: 'integrate_info_department_name', label: '部署', sortable: true },
    { key: 'created_at', label: '連携日時', sortable: true },
    { key: 'linking', label: '連携方法', sortable: true },
    { key: 'integrate_info_status', label: 'ステータス', sortable: true },
  ]
}

async function getProfileOptions(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `${baseUrl}/${subscribe_apps_id}/profile`
  const response = await axiosIn
    .get(url)
    .then(async (res) => {
      return needRefreshToken(res.data, () => getProfileOptions(subscribe_apps_id))
    })
    .catch((err) => {
      return err
    })
  if (!get(response, 'success', false)) {
    return {
      success: false,
      items: response,
    }
  }
  return {
    success: response.success,
    items: response.data || [],
  }
}

async function needRefreshToken(response, callback) {
  const statusCode = get(response, 'statusCode', '')
  if (statusCode !== STATUS_CODE.UNAUTHORIZED) {
    return response
  }
  await store.dispatch('loading/showManualLoading')
  try {
    const savedMetadata = store.getters['saas/savedMetadata']
    const service = store.getters['saas/service']
    const params = {
      client_id: process.env.VUE_APP_SALESFORCE_CLIENT_ID,
      client_secret: process.env.VUE_APP_SALESFORCE_CLIENT_SECRET,
      refresh_token: savedMetadata.refresh_token,
      grant_type: 'refresh_token',
    }
    // sonar warning here but it's ok
    const result = await getAccessToken(params)
    if (!result) {
      await store.dispatch('loading/hideManualLoading')
      return {
        success: false,
        message: MESSAGES.COMMON.MSG15,
      }
    }
    await saasCommonService.addToken(service.id, { ...savedMetadata, ...result })
    await store.dispatch('saas/setServiceMetadata', { ...savedMetadata, ...result })
    await store.dispatch('loading/hideManualLoading')
    return callback()
  } catch (e) {
    await store.dispatch('loading/hideManualLoading')
  }
}

function handleError(errorCode, errorMessages) {
  const result = {
    success: false,
    message: '',
  }
  if (errorCode.indexOf('LICENSE_LIMIT_EXCEEDED') !== -1) {
    result.message = MESSAGES.SAAS_CONNECT.SF01
  }
  if (errorCode.indexOf('DUPLICATE_USERNAME') !== -1) {
    result.message = MESSAGES.SAAS_CONNECT.SF02
  }
  if (errorCode.indexOf('FIELD_INTEGRITY_EXCEPTION') !== -1) {
    result.message = MESSAGES.SAAS_CONNECT.SF03
  }
  if (errorMessages && errorMessages.indexOf('self-deactivate') !== -1) {
    result.message = MESSAGES.SAAS_CONNECT.LK11
  }
  return result
}

function validatePassword(value) {
  return /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?).{8,}$/.test(value.toLowerCase())
}

export const salesForceService = {
  getAccessToken,
  getDialogState,
  fetchListUser,
  addUser,
  deleteUser,
  getSaaSAccountFields,
  getSaaSRemoveDialogFields,
  getProfileOptions,
  validatePassword,
}
