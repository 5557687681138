<template>
  <common-modal :id="id" :buttons="buttons">
    <p>
      このプロフィールにユーザー作成もしくは更新するために、以下の各ステップの通りにしてください。
    </p>
    <p>１．「設定」にアクセスします。</p>
    <p>２．「デジタルエクスペリエンス」をクリックして、「設定」を選択します。</p>
    <p>
      ３．「セルフ登録、ユーザー作成、およびログインで標準外部プロファイールの使用を許可」を選択します。
    </p>
  </common-modal>
</template>

<script>
import CommonModal from '@/components/commons/common-modal'
export default {
  name: 'SalesforceInforModal',
  components: { CommonModal },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
      ],
    }
  },
}
</script>
